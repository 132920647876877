<template>
  <HeroBg
    heroTitle="Amplify Your Employer Brand to Reach the <br class='hidden xl:inline-block' /> Right Talent"
    heroDesc="Craft an irresistible employer identity and attract Africa’s crème de la crème talent"
    heroBtnText="Get Started"
    heroBtnLink="/contact"
    heroImg="hero10.png"
  />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[65vw]">
      <div class="py-8">
        <br />
        <h2 class="mb-4 text-center text-[14px] md:text-[50px] font-medium">Who's getting ahead with CareerBuddy?</h2>
        <div class="flex flex-wrap items-center justify-start lg:justify-between rounded-lg mb-4">
          <img src="@/assets/images/bolt.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-[98px]" alt="" />
          <img src="@/assets/images/moniepoint.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-[128px]" alt="" />
          <img src="@/assets/images/shuttlers.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-[150px]" alt="" />
          <img src="@/assets/images/zikoko.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-[150px]" alt="" />
          <img src="@/assets/images/sabi.png" class="mr-3 mb-3 md:mb-0 w-[60px] md:w-[97px]" alt="" />
          <img src="@/assets/images/skillpaddy.png" class="w-[60px] md:w-[130px]" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[83vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <div class="block lg:flex items-center gap-x-8">
          <img src="@/assets/images/choice.png" class="w-full lg:w-[500px] mb-8 lg:mb-0" alt="" />
          <div>
            <h1 class="text-[32px] md:text-[40px] leading-[110%] mb-4 font-semibold text-brand-black">
              We are the first choice platform for African professionals looking for their next move
            </h1>
            <p class="text-[17px] md:text-[21px] text-black/50">
              At CareerBuddy, we believe your brand is a narrative waiting to captivate the hearts of top-notch professionals across the diverse and
              dynamic African landscape. Our bespoke Employer Branding Service is your compass to navigating the talent-rich terrains of Africa,
              magnetizing the right minds that resonate with your brand’s ethos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full lg:w-[78vw] px-[24px] md:px-0">
      <div class="py-[5rem]">
        <div class="px-[24px] md:px-0">
          <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">What do you Get?</h2>
        </div>

        <div class="md:flex flex-wrap lg:justify-center gap-8 pb-5">
          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/icons/users.svg" class="mb-4" alt="" />
            <h1 class="font-bold text-[18px] md:text-[20px]">Tailored Branding Strategy</h1>
            <p class="leading-[140%]">
              Our seasoned brand strategists delve into the unique narrative of your organization, crafting a bespoke employer branding strategy that
              resonates with the aspirations of top talents across Africa.
            </p>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/icons/users.svg" class="mb-4" alt="" />
            <h1 class="font-bold text-[18px] md:text-[20px]">Authentic Storytelling</h1>
            <p class="leading-[140%]">
              We unveil the authentic narrative of your brand, weaving a compelling story that speaks to the hearts of potential candidates,
              portraying your company as a beacon of opportunity and growth.
            </p>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/icons/users.svg" class="mb-4" alt="" />
            <h1 class="font-bold text-[18px] md:text-[20px]">Engaging Visual Content</h1>
            <p class="leading-[140%]">
              Our creative team conjures engaging visual content that encapsulates the spirit of your brand, creating a vivid imprint in the minds of
              your target talent pool.
            </p>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/icons/users.svg" class="mb-4" alt="" />
            <h1 class="font-bold text-[18px] md:text-[20px]">Insightful Analytics</h1>
            <p class="leading-[140%]">
              Leverage our cutting-edge digital platforms to interact with potential candidates, offering a glimpse into your organization's culture
              and values.
            </p>
          </div>

          <div class="w-full md:w-1/4 mb-4 md:mb-0 bg-white rounded-lg p-8">
            <img src="@/assets/icons/users.svg" class="mb-4" alt="" />
            <h1 class="font-bold text-[18px] md:text-[20px]">Interactive Digital Platforms</h1>
            <p class="leading-[140%]">
              Measure the impact of your employer branding with insightful analytics, understanding how your brand resonates in the African talent
              market.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[85vw] px-[24px] md:px-0">
      <div class="py-[5rem]">
        <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Why Bother about Employer Branding?</h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 pb-5">
          <div class="p-8 rounded-t-xl bg-brand-black mb-3 md:mb-0">
            <p class="text-[18px] md:text-[21px] leading-[32px] font-bold text-white">Attract Elite Talent</p>
            <p class="text-white leading-[24px]">
              Elevate your brand to become a magnet for the finest professionals, eager to contribute their expertise to your vision
            </p>
          </div>

          <div class="p-8 rounded-t-xl bg-brand-black mb-3 md:mb-0">
            <p class="text-[18px] md:text-[21px] leading-[32px] font-bold text-white">Attract Elite Talent</p>
            <p class="text-white leading-[24px]">
              Elevate your brand to become a magnet for the finest professionals, eager to contribute their expertise to your vision
            </p>
          </div>

          <div class="p-8 rounded-t-xl bg-brand-black mb-3 md:mb-0">
            <p class="text-[18px] md:text-[21px] leading-[32px] font-bold text-white">Attract Elite Talent</p>
            <p class="text-white leading-[24px]">
              Elevate your brand to become a magnet for the finest professionals, eager to contribute their expertise to your vision
            </p>
          </div>

          <div class="p-8 rounded-t-xl bg-brand-black mb-3 md:mb-0">
            <p class="text-[18px] md:text-[21px] leading-[32px] font-bold text-white">Attract Elite Talent</p>
            <p class="text-white leading-[24px]">
              Elevate your brand to become a magnet for the finest professionals, eager to contribute their expertise to your vision
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-brand-black">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-white text-center">
        <h1 class="text-white text-[32px] md:text-[60px]">Ready to work together?</h1>

        <p class="text-[18px] md:text-[32px] leading-[110%] mb-[65px] text-white/50">
          Partner with CareerBuddy today, and let’s craft a narrative that propels your <br class="hidden md:inline-block" />
          organization into the hearts and minds of Africa’s most coveted professionals
        </p>
        <br />
        <div>
          <a href="/contact" class="rounded-2xl px-8 md:px-16 py-4 text-center bg-light-yellow text-brand-black text-[15px] md:text-[18px]"
            >Start Hiring</a
          >
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import HeroBg from '@/components/partials/HeroBg.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import { useMeta } from '@/utils/meta';

useMeta();
</script>
